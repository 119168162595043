export const genderList = [{ name: "Female", arabic: "أنثى" }, { name: "Male", arabic: "ذكر" }];
export const marritialList = [{ name: "Single", arabic: "أعزب" }, { name: "Married", arabic: "متزوج" }, { name: "Widow", arabic: "أرملة" }];
export const day = [{ name: "Day", value: "day", arabic: "يوم" }, { name: "Week", value: "week", arabic: "أسبوع" }, { name: "Month", value: "month", arabic: "شهر" }]
export const marritialListDubai = [{ name: "Divorced", arabic: "مُطلّق" }, { name: "Married", arabic: "متزوج" }, { name: "Single", arabic: "أعزب" }, { name: "Widowed", arabic: "الأرامل" }];
export const uniqueFamilyIdentifier = [{ name: "Father", arabic: "أب" }, { name: "Family", arabic: "عائلة" }, { name: "Mother", arabic: "الأم" }, { name: "Next of kin", arabic: "الأقرباء" }, { name: "Sibling", arabic: "أخ أو أخت" }, { name: "Spouse", arabic: "زوج" }, { name: "Unrelated friend", arabic: "صديق غير مرتبط" },];
export const employeementStatus = [{ name: "Government Employee (Military)", arabic: "موظف حكومي (عسكري)" }, { name: "Freelancer", arabic: "مستقل" }, { name: "Housewife", arabic: "ربه منزل" }, { name: "Other", arabic: "آخر" }, { name: "Public Sector Employee", arabic: "موظف في القطاع العام" }, { name: "Private Sector Employee", arabic: "موظف في القطاع الخاص" }, { name: "Refused to answer", arabic: "رفض الإجابة" }, { name: "Retired", arabic: "متقاعد" }, { name: "Student", arabic: "طالب" }, { name: "Unemployed (Can work)", arabic: "عاطل عن العمل (يستطيع العمل)" }, { name: "Unemployed (Cannot work)", arabic: "عاطل عن العمل (لا أستطيع العمل)" }, { name: "Unknown", arabic: "مجهول" }, { name: "Volunteer - Co-op", arabic: "تطوع- تعاونية" }];
export const insuranceElgibility = [{ name: "Aramco", arabic: "أرامكو" }, { name: "Education", arabic: "تعليم" }, { name: "KFSHRC", arabic: "KFSHRC" }, { name: "Military", arabic: "جيش" }, { name: "Ministry of interior", arabic: "وزارة الداخلية" }, { name: "National guard", arabic: "الحرس الوطني" }, { name: "Public sector (non-military nor education)", arabic: "القطاع العام (غير العسكري ولا التعليمي)" }, { name: "Private", arabic: "خاص" }, { name: "Royal commission", arabic: "الهيئة الملكية" }];
export const relationList = [{ name: "Brother", arabic: "أخ" }, { name: "Father", arabic: "أب" }, { name: "Mother", arabic: "الأم" }, { name: "Others", arabic: "آحرون" }];
export const profession = [{ name: "Administration", arabic: "إدارة" }, { name: "Agriculture", arabic: "زراعة" }, { name: "Business", arabic: "عمل" }, { name: "Education", arabic: "تعليم" }, { name: "Housewife", arabic: "ربه منزل" }, { name: "Marine", arabic: "البحرية" }, { name: "Medical field", arabic: "المجال الطبي" }, { name: "Military", arabic: "جيش" }, { name: "Others", arabic: "آحرون" }, { name: "Oil industries", arabic: "الصناعات النفطية" }, { name: "Skilled worker", arabic: "عامل ماهر" }, { name: "Student", arabic: "طالب" }, { name: "Unemployed", arabic: "غير موظف" }, { name: "Unknown", arabic: "مجهول" }];
export const educationStatus = [{ name: "Dip.LTUniv", arabic: "Dip.LTUniv" }, { name: "Illiterate", arabic: "أمي" }, { name: "Intermediate", arabic: "متوسط" }, { name: "Masters/High Dip.", arabic: "الماجستير/الانحدار العالي." }, { name: "Read and Write", arabic: "اقرا و اكتب" }, { name: "Primary", arabic: "أساسي" }, { name: "PhD", arabic: "PhD" }, { name: "Secondary", arabic: "ثانوي" }, { name: "University", arabic: "جامعة" }, { name: "Unknown", arabic: "مجهول" },];
export const generalHealthStatus = [{ name: "Bad", arabic: "سيء" }, { name: "Good", arabic: "جيد" }, { name: "Fair", arabic: "عدل" }, { name: "Very good", arabic: "جيد جدًا" }, { name: "Very bad", arabic: "سيئ جدا" }];
export const levelofactivity = [{ name: "150 minutes or more", arabic: "150 دقيقة أو أكثر" }, { name: "From 75 minutes to 149 minutes", arabic: "من 75 دقيقة إلى 149 دقيقة" }, { name: "From 0 minutes to 75 minutes", arabic: "من 0 دقيقة إلى 75 دقيقة" }, { name: "No activity", arabic: "لا يوجد نشاط" }, { name: "Unknown", arabic: "مجهول" }];
export const insrelationList = [{ name: "Childe", arabic: "طفل" }, { name: "Self", arabic: "الذات" }, { name: "Spouse", arabic: "زوج" }, { name: "Other", arabic: "آخر" }];
export const smoking = [{ name: "Ex-Smoker", arabic: "مدخن سابق" }, { name: "No", arabic: "لا" }, { name: "Refused to answer", arabic: "رفض الإجابة" }, { name: "Yes, started within the last year", arabic: "نعم، بدأت خلال العام الماضي" }, { name: "Yes, started before last year", arabic: "نعم، بدأت قبل العام الماضي" }];
export const ChronicconditionDiabetes = [{ name: "Gestational Diabetes", arabic: "سكري الحمل" }, { name: "Impaired Glucose Homeostasis", arabic: "ضعف توازن الجلوكوز" }, { name: "No", arabic: "لا" }, { name: "Other", arabic: "آخر" }, { name: "Prediabetic", arabic: "مقدمات السكري" }, { name: "Type 1", arabic: "النوع 1" }, { name: "Type 2", arabic: "النوع 2" }, { name: "Unknown", arabic: "مجهول" }];
export const hematologicConditions = [{ name: "Acquired haemolytic anaemia", arabic: "فقر الدم الانحلالي المكتسب" }, { name: "No", arabic: "لا" }, { name: "Sickle-cell disorders", arabic: "اضطرابات الخلايا المنجلية" }, { name: "Thalassaemia and other genetic haemolytic", arabic: "الثلاسيميا وغيرها من أمراض الدم الوراثية" }];
export const ChronicConditionCancer = [{ name: "Breast", arabic: "صدر" }, { name: "Colon", arabic: "القولون" }, { name: "Gastrointestinal sites (Other than Liver)", arabic: "مواقع الجهاز الهضمي (بخلاف الكبد)" }, { name: "Kidney", arabic: "كلية" }, { name: "Lymphoma", arabic: "سرطان الغدد الليمفاوية" }, { name: "Leukemia", arabic: "سرطان الدم" }, { name: "Lung and respiratory system", arabic: "الرئة والجهاز التنفسي" }, { name: "Liver", arabic: "الكبد" }, { name: "Malignant CNS and Peripheral Nerves", arabic: "الجهاز العصبي المركزي الخبيث والأعصاب الطرفية" }, { name: "Non-Malignant CNS", arabic: "الجهاز العصبي المركزي غير الخبيث" }, { name: "Ovary and female reproductive system", arabic: "المبيض والجهاز التناسلي الأنثوي" }, { name: "Others", arabic: "آحرون" }, { name: "Thyroid", arabic: "غدة درقية" }, { name: "Urinary sites", arabic: "مواقع المسالك البولية" }, { name: "Prostate", arabic: "البروستات" }, { name: "Unknown", arabic: "مجهول" }];
export const Dementia = [{ name: "Alzheimer's Disease", arabic: "مرض الزهايمر" }, { name: "Dementia", arabic: "الخَرَف" }, { name: "No", arabic: "لا" }, { name: "Parkinsonism", arabic: "الشلل الرعاش" }];
export const dischargeStatusList = [{ name: "Improved", arabic: "تحسين" }, { name: "No Improved", arabic: "لا يوجد تحسن" }, { name: "Transferred to ED", arabic: "تم النقل إلى الضعف الجنسي" }, { name: "Hospital admission", arabic: "دخول المستشفى" }, { name: "Self-care", arabic: "رعاية ذاتية" }]
export const pastMedicalHistory = [{ name: "Hospitalization", arabic: "العلاج في المستشفيات" }, { name: "Surgery", arabic: "جراحة" }, { name: "Blood transfusion", arabic: "نقل الدم" }, { name: "Other", arabic: "آخر" },]
export const diseases = [{ name: "Diabetes mellitus", arabic: "السكرى" }, { name: "Hypertension", arabic: "ارتفاع ضغط الدم" }, { name: "Hyperlipidemia", arabic: "ارتفاع شحوم الدم" }, { name: "Respiratory diseases", arabic: "أمراض الجهاز التنفسي" }, { name: "Cardiac diseases", arabic: "أمراض القلب" }, { name: "Neurological diseases", arabic: "الأمراض العصبية" }, { name: "Thyroid diseases", arabic: "أمراض الغدة الدرقية" }, { name: "Others", arabic: "آحرون" }];
export const eatDifficulties = [{ name: "Chewing ability", arabic: "القدرة على المضغ" }, { name: "Swallowing ability", arabic: "القدرة على البلع" }, { name: "GI Complications", arabic: "مضاعفات الجهاز الهضمي" }, { name: "Diarrhea", arabic: "إسهال" }, { name: "Constipation", arabic: "إمساك" }, { name: "Nausea", arabic: "غثيان" }, { name: "Vomiting", arabic: "القيء" }, { name: "None", arabic: "لا أحد" }]
export const MentalCondition = [
  { name: "Anxiety Disorders", arabic: "اضطرابات القلق" },
  { name: "Depression disorder", arabic: "اضطراب الاكتئاب" },
  { name: "Bipolar disorder", arabic: "اضطراب ذو اتجاهين" },
  { name: "Eating disorder", arabic: "اضطرابات الطعام" },
  { name: "Others", arabic: "آحرون" },
  { name: "Personality disorder", arabic: "اضطراب في الشخصية" },
  { name: "Post-Traumatic stress disorder", arabic: "اضطراب ما بعد الصدمة" },
  { name: "Psychotic Disorders", arabic: "الاضطرابات الذهانية" },
  { name: "Unknown", arabic: "مجهول" }
]
export const cities = [
  { name: "Riyadh", arabic: "الرياض" },
  { name: "Jeddah", arabic: "جدة" },
  { name: "Mecca", arabic: "مكة" },
  { name: "Medina", arabic: "المدينة المنورة" },
  { name: "Dammam", arabic: "الدمام" },
  { name: "Khobar", arabic: "الخبر" },
  { name: "Tabuk", arabic: "تبوك" },
  { name: "Abha", arabic: "أبها" },
  { name: "Khamis Mushait", arabic: "خميس مشيط" },
  { name: "Hofuf", arabic: "الهفوف" },
  { name: "Jubail", arabic: "الجبيل" },
  { name: "Al Qassim", arabic: "القصيم" },
  { name: "Yanbu", arabic: "ينبع" },
  { name: "Al Bahah", arabic: "الباحة" },
  { name: "Najran", arabic: "نجران" },
  { name: "Hail", arabic: "حائل" },
  { name: "Qatif", arabic: "القطيف" },
  { name: "Al Khafji", arabic: "الخفجي" },
  { name: "Taif", arabic: "الطائف" }
];
export const sourceOfReferralList = [
  { name: "Baitna Employee", arabic: "موظف بيتنا" }, { name: "Other Patients", arabic: "مرضى آخرين" }, { name: "Social Media", arabic: "وسائل التواصل الاجتماعي" }, { name: "Website", arabic: "موقع إلكتروني" }
]

export const patientType = [
  { name: "Corporate", arabic: "الشركات" }, { name: "Expat", arabic: "مغترب" }, { name: "Saudi", arabic: "سعودي" }
]
export const taxables = [
  { name: "Corporate - Taxable", arabic: "الشركات - خاضعة للضريبة" },
  { name: "Non Saudi Citizen - Taxable", arabic: "مواطن غير سعودي - خاضع للضريبة" },
  { name: "Saudi Citizen - Non Taxable", arabic: "مواطن سعودي - غير خاضع للضريبة" },


];

export const financeLevelRevenue = [
  { name: "Physical Visits", arabic: "زيارات المرضى" },
  { name: "Teleconsultation", arabic: "استشارة عن بعد" },
  { name: "Corporate", arabic: "الشركات" },
  { name: "Laboratory Sales", arabic: "مبيعات المختبر" },
  { name: "Auxiliary Services", arabic: "الخدمات المساندة" },
  { name: "Physiotherapy Sales", arabic: "مبيعات العلاج الطبيعي" },
  { name: "Food and Beverage Sales", arabic: "مبيعات الأغذية والمشروبات" },
  { name: "Other Revenue", arabic: "إيرادات أخرى" }
];

export const typeList = [
  { name: "Service", arabic: "الخدمة" },
  { name: "Item", arabic: "العنصر" }
];
export const patientCategoryList = [
  { name: "Corporate", arabic: "الشركات" },
  { name: "Non-Corporate", arabic: "غير الشركات" }
];
export const paymentType = [
  { name: "Cash", arabic: "نقدي" },
  { name: "Credit", arabic: "ائتمان" },
  { name: "Insurance", arabic: "تأمين" },
  { name: "FOC", arabic: "FOC" }
];

export const specialityList = [
  { name: "Doctor", arabic: "طبيب" },
  { name: "Nurse", arabic: "ممرضة" },
  { name: "Physiotherapist", arabic: "أخصائي العلاج الطبيعي" },
  { name: "Care Giver", arabic: "مقدم الرعاية" },
  { name: "Occupational Therapist", arabic: "أخصائي العلاج الوظيفي" },
  { name: "Paramedics", arabic: "مسعفون" },
  { name: "Admin", arabic: "إداري" },
  { name: "Drivers", arabic: "سائقون" },
  { name: "Doctor and Nurse", arabic: "طبيب وممرضة" }
];

export const locationList = [
  { name: "Kingdom Center, Riyadh", arabic: "مركز المملكة، الرياض" },
  { name: "Al-Malaz, Riyadh", arabic: "الملز، الرياض" },
  { name: "Al-Balad, Jeddah", arabic: "البلد، جدة" },
  { name: "Corniche, Jeddah", arabic: "الكورنيش، جدة" },
  { name: "Mina, Mecca", arabic: "منى، مكة" },
  { name: "Al-Naseem, Mecca", arabic: "النسيم، مكة" },
  { name: "Quba Mosque, Medina", arabic: "مسجد قباء، المدينة" },
  { name: "Al-Ula, Medina", arabic: "العلا، المدينة" },
  { name: "King Fahd Park, Dammam", arabic: "منتزه الملك فهد، الدمام" },
  { name: "Al Khobar Corniche, Khobar", arabic: "كورنيش الخبر، الخبر" },
  { name: "Red Sea Mall, Jeddah", arabic: "رد سي مول، جدة" },
  { name: "Prince Sultan Road, Abha", arabic: "شارع الأمير سلطان، أبها" },
  { name: "Shamsan Castle, Abha", arabic: "قلعة شمسان، أبها" },
  { name: "Tabuk Castle, Tabuk", arabic: "قلعة تبوك، تبوك" },
  { name: "Al Hada Mountain, Taif", arabic: "جبل الهدا، الطائف" },
  { name: "Al-Rawdah, Hail", arabic: "الروضة، حائل" }
];

export const employmentTypeList = [
  { name: "Full Time", arabic: "دوام كامل" },
  { name: "Part Time", arabic: "دوام جزئي" },
  { name: "Consultant", arabic: "مستشار" },
  { name: "Commission", arabic: "عمولة" },
  { name: "Tamheer", arabic: "تمهير" },
  { name: "Outsourced", arabic: "متعهد" },
  { name: "Student_Trainee", arabic: "طالب متدرب" },
  { name: "Musanada", arabic: "مساندة" }
];
export const PTtypeList = [
  { name: "Commission", arabic: "عمولة" },
  { name: "Hourly", arabic: "أجر بالساعة" },
  { name: "Fixed", arabic: "أجر ثابت" }
];
export const Status = [
  { name: "Waiting List", arabic: "قائمة الانتظار" },
  { name: "Completed", arabic: "مكتمل" }
]
export const paymentTypes = [
  { name: "Partially paid", arabic: "المدفوعة جزئيا" },
  { name: "Pending", arabic: "قيد الانتظار" },
  { name: "Paid", arabic: "مدفوع" },
]
export const CardioVescular = [
  { name: "Arrhythmia", arabic: "عدم انتظام ضربات القلب" }, { name: "Cardiomyopathies", arabic: "اعتلال عضلة القلب" }, { name: "Myocardial Infarction (Ischemic Heart Disease)", arabic: "احتشاء عضلة القلب (مرض القلب الإقفاري)" }, { name: "Rheumatic Heart Disease", arabic: "مرض روماتيزم القلب" },
  { name: "Pericarditis", arabic: "التهاب التامور" }, { name: "Others", arabic: "آحرون" }, { name: "Unknown", arabic: "مجهول" },

]
export const CommunicableDiseases = [
  { name: "Hepatitis A", arabic: "إلتهاب الكبد أ" }, { name: "Hepatitis B", arabic: "التهاب الكبد ب" }, { name: "Hepatitis C", arabic: "التهاب الكبد ج" }, { name: "Dengue Fever", arabic: "حمى الضنك" },
  { name: "Brucellosis", arabic: "داء البروسيلات" }, { name: "Leishmaniasis", arabic: "داء الليشمانيات" }, { name: "Tuberculosis", arabic: "مرض الدرن" }, { name: "Lower Respiratory Tract Infections", arabic: "التهابات الجهاز التنفسي السفلي" }, { name: "Upper Respiratory Tract Infections", arabic: "التهابات الجهاز التنفسي العلوي" },
  { name: "Malaria", arabic: "ملاريا" }, { name: "Leprosy", arabic: "جذام" }, { name: "Meningitis", arabic: "التهاب السحايا" }, { name: "Unknown", arabic: "مجهول" },


]
export const severeChronicConditionsRequireTransplan = [
  { name: "Heart", arabic: "قلب" }, { name: "Lung", arabic: "رئة" },
  { name: "Kidney", arabic: "كلية" }, { name: "Liver", arabic: "الكبد" },
  { name: "Dialysis", arabic: "غسيل الكلى" }, { name: "Others", arabic: "آحرون" },
  { name: "Unknown", arabic: "مجهول" },

]
export const Asthma = [
  { name: "Asthma", arabic: "الربو" },
  { name: "Chronic Bronchitis", arabic: "التهاب الشعب الهوائية المزمن" },
  { name: "Emphysema", arabic: "انتفاخ الرئة" },
  { name: "Unknown", arabic: "مجهول" },

]
export const disability = [
  { name: "Sight", arabic: "رؤية" },
  { name: "Self-care", arabic: "رعاية ذاتية" }, { name: "Hearing", arabic: "سمع" },
  { name: "Mobility", arabic: "إمكانية التنقل" }, { name: "Memory and concentration", arabic: "الذاكرة والتركيز" },
  { name: "Communication", arabic: "تواصل" }, { name: "Others", arabic: "آحرون" },
  { name: "Unknown", arabic: "مجهول" },

]
export const seStatesList = ["Alaska", "Newyork", "Huwai"];
export const seCountryList = ["India", "USA", "Uk"];
export const yesOrNoList = ["Yes", "No"];
export const insuranceTypeList = ["Primary", "Secondary", "Tertiary"];
export const addressTypeList = ["Present", "Permanent"];
export const wardTypeList = ["General/Male", "General/Female", "Special/Male", "Special/Female"];
export const testResults = ["Pending", "Performed", "Rejected"];
export const testStatus = ["Positive", "Negative"];

export const paymentStatus = ["Pending", "Paid"];

export const paymentMethods = ["Debit Card", "Credit Card", "Cash", "UPI"];

export const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const RoomType = ["Consultation Room", "Sevice Room", "OT Room", "Wards"];
export const status = ["Active", "Inactive"];
export const appointmentStatus = ["Requested", "Booked", "Consulted", "Sevice", "Completed"];
export const timesPer = ["Day", "Week", "Month", "As needed"];
export const timesOfDay = ["AM", "PM", "Both", "With Meals"];
export const dosageList = ["mg", "drops", "fl.oz", "g", "ic", "mcg", "ml", "oz", "fbsp", "tsp", "units/ml"];
export const medicationRoute = ["G-tube", "Infusion", "Inhaled ", "Injections", "Intramuscular Injection", "Instill", "Nasal", "Oral", "Rectal", "Skin", "Sub-Lingual", "Subcutaneous Injection", "Subcutaneous Solution", "Subdermal Implant", "Topical", "Unknown or N/A", "Unsure", "Vaginal"];

export const attachmentCertificates = ["Driving Licence", "Passport", "CME Certificate", "FellowShip Certificate", "Recidency Certificate", "Graduate Certificate", "Internship Certificate", "Undergraduate Certificate", "ACLS Certificate", "CDS Certificate", "Board Certificate", "DEA Certificate", "Medical Licence Certificate", "Hospital Privilege Letters for all current facilities", "Last Flu Shot(Last 12 Months)", "PPD Results(Last 12 Months)"];

export const credStatusList = [
  { name: "Approve", value: "3" },
  { name: "Temporarily approve", value: "4" },
  { name: "Reject", value: "5" },
];

export const tableData = [
  { heading: "Cervical flexion", name: "cervical_flexion", normalValue: "0-45" },
  { heading: "Cervical ext", name: "cervical_ext", normalValue: "0-45" },
  { heading: "Cervical lat flexion", name: "cervical_lat_flexion", normalValue: "0-45" },
  { heading: "Cervical rotation", name: "cervical_rotation", normalValue: "0-60" },
  { heading: "Shoulder flexion", name: "shoulder_flexion", normalValue: "0-180" },
  { heading: "Shoulder Ext", name: "shoulder_ext", normalValue: "0-50" },
  { heading: "Shoulder  Abduction", name: "shoulder_abduction", normalValue: "0-180" },
  { heading: "Shoulder Add", name: "shoulder_add", normalValue: "0-45" },
  { heading: "Shoulder IR", name: "shoulder_iR", normalValue: "0-90" },
  { heading: "Shoulder ER", name: "shoulder_eR", normalValue: "0-90" },
  { heading: "Elbow flexion", name: "elbow_flexion", normalValue: "0-145" },
  { heading: "Elbow Extension", name: "elbow_extension", normalValue: "0-0" },
  { heading: "FA pronation", name: "FA_pronation", normalValue: "0-85" },
  { heading: "FA supination", name: "FA_supination", normalValue: "0-85" },
  { heading: "Wrist flexion", name: "Wrist_flexion", normalValue: "0-70" },
  { heading: "Wrist extension", name: "Wrist_extension", normalValue: "0-70" },
  { heading: "Wrist ulnar deviation", name: "Wrist_ulnar_deviation", normalValue: "0-45" },
  { heading: "Wrist radial deviation", name: "Wrist_radial_deviation", normalValue: "0-20" },
  { heading: "Hip flexion", name: "Hip_flexion", normalValue: "0-120" },
  { heading: "Hip extension", name: "Hip_extension", normalValue: "0-25" },
  { heading: "Hip abduction", name: "Hip_abduction", normalValue: "0-45" },
  { heading: "Hip adduction", name: "Hip_adduction", normalValue: "0-30" },
  { heading: "Knee flexion", name: "Knee_flexion", normalValue: "0-135" },
  { heading: "Knee extension", name: "Knee_extension", normalValue: "0-0" },
  { heading: "Ankle DF", name: "Ankle_DF", normalValue: "0-20" },
  { heading: "Ankle PF", name: "Ankle_PF", normalValue: "0-50" },
  { heading: "Ankle IR", name: "Ankle_IR", normalValue: "0-35" },
  { heading: "Ankle ER", name: "Ankle_ER", normalValue: "0-20" },
];
export const timesPer2 = ["Once a day", "Twice a day", "Three times a day", "Four times a day", "As prescribed"];
export const roles = [
  { roleId: 3, rolename: "Admin", arabic: "مسؤل" },
  { roleId: 14, rolename: "Coordinator", arabic: "مسؤل" },
  { roleId: 2, rolename: "Portfolio Lead", arabic: "الرصاص المحفظة" },
  // { roleId: 3, rolename: "Manager", arabic: "مدير" },
  { roleId: 4, rolename: "Provider", arabic: "طبيب" },
  { roleId: 5, rolename: "Patient", arabic: "مريض" },
  { roleId: 6, rolename: "Lab Collection Agent", arabic: "وكيل جمع المختبر" },
  { roleId: 7, rolename: "Lab Technician", arabic: "فني مختبر" },
  { roleId: 8, rolename: "Non Login Users", arabic: "المستخدمين غير المسجلين" },
  { roleId: 9, rolename: "Nurse", arabic: "ممرضة" },
  { roleId: 10, rolename: "HOD", arabic: "هود" },
  { roleId: 11, rolename: "Receptionist", arabic: "موظف الإستقبال" },
  { roleId: 12, rolename: "IT Admin", arabic: "هو - هي مسؤل" },

];

